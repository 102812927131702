var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Tooltip Triggers" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeTriggers) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _vm._v(
          " Tooltip is triggered using - click | hover | focus | manual options.You may pass multiple triggers; separate them with a space. "
        )
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.click.v-primary",
                  modifiers: { click: true, "v-primary": true }
                }
              ],
              attrs: { title: "On Cllick Trigger", variant: "outline-primary" }
            },
            [_vm._v(" On Cllick Trigger ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.v-primary",
                  modifiers: { hover: true, "v-primary": true }
                }
              ],
              attrs: { title: "On Hover Trigger", variant: "outline-primary" }
            },
            [_vm._v(" On Hover Trigger ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.focus.v-primary",
                  modifiers: { hover: true, focus: true, "v-primary": true }
                }
              ],
              attrs: {
                title: "Hover + Focus Trigger",
                variant: "outline-primary"
              }
            },
            [_vm._v(" Hover + Focus Trigger ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.focus.v-primary",
                  modifiers: { focus: true, "v-primary": true }
                }
              ],
              attrs: { title: "On Focus Trigger", variant: "outline-primary" }
            },
            [_vm._v(" On Focus Trigger ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }