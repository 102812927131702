var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Tooltip Methods" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeMethods) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _vm._v(
          " This is considered a “manual” triggering of the tooltip. Tooltips with zero-length titles are never displayed. "
        )
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { id: "tooltip-button-1", variant: "outline-primary" },
              on: { click: _vm.showTooltip }
            },
            [_vm._v(" Show Toggle Tooltip ")]
          ),
          _c(
            "b-tooltip",
            {
              attrs: {
                target: "tooltip-button-1",
                placement: "top",
                triggers: "click"
              }
            },
            [_vm._v(" tooltip! ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { id: "tooltip-button-2", variant: "outline-primary" },
              on: { click: _vm.hideTooltip }
            },
            [_vm._v(" Hide Toggle Tooltip ")]
          ),
          _c(
            "b-tooltip",
            {
              attrs: {
                target: "tooltip-button-2",
                placement: "top",
                triggers: "hover"
              }
            },
            [_vm._v(" tooltip! ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { id: "tooltip-button-3", variant: "outline-primary" },
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [_vm._v(" Toggle Tooltip ")]
          ),
          _c(
            "b-tooltip",
            {
              attrs: {
                show: _vm.show,
                target: "tooltip-button-3",
                placement: "top",
                triggers: "click"
              },
              on: {
                "update:show": function($event) {
                  _vm.show = $event
                }
              }
            },
            [_vm._v(" tooltip! ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: {
                id: "tooltip-button-disable",
                variant: "outline-primary"
              }
            },
            [_vm._v(" I have a tooltip ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: _vm.disabled ? "success" : "danger" },
              on: {
                click: function($event) {
                  _vm.disabled = !_vm.disabled
                }
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.disabled ? "Enable" : "Disable") +
                  " Tooltip by prop "
              )
            ]
          ),
          _c(
            "b-tooltip",
            {
              ref: "tooltip",
              attrs: {
                disabled: _vm.disabled,
                target: "tooltip-button-disable"
              },
              on: {
                "update:disabled": function($event) {
                  _vm.disabled = $event
                }
              }
            },
            [_c("span", [_vm._v("Hello ")]), _c("strong", [_vm._v("World!")])]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }