var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Tooltip Options" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeOptions) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [_vm._v("Use the ")]),
        _c("code", [_vm._v("nofade")]),
        _c("span", [_vm._v(" prop to turn off animation.")]),
        _c("span", [
          _vm._v(
            "Delay showing and hiding the tooltip (ms). If a number is supplied, delay is applied to both hide/show. Object structure is: "
          )
        ]),
        _c("code", [_vm._v('delay: { "show": 500, "hide": 100 }')])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { id: "tooltip-target-1", variant: "outline-primary" }
            },
            [_vm._v(" No animation ")]
          ),
          _c(
            "b-tooltip",
            {
              attrs: {
                target: "tooltip-target-1",
                triggers: "hover",
                "no-fade": ""
              }
            },
            [_vm._v(' Without Fade Animation class="mb-1 mb-md-0" ')]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { id: "tooltip-target-2", variant: "outline-primary" }
            },
            [_vm._v(" Delay Tooltip ")]
          ),
          _c(
            "b-tooltip",
            { attrs: { target: "tooltip-target-2", delay: _vm.delay } },
            [_vm._v(" Delay triggers ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }