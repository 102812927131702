var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Variants and custom class" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeVariantCustom) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _vm._v(
          " BootstrapVue's tooltips support contextual color variants via our custom CSS, either by using directive modifiers or config options: "
        )
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true }
                }
              ],
              attrs: { title: "Default variant", variant: "gradient-primary" }
            },
            [_vm._v(" Default ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.v-primary",
                  modifiers: { hover: true, "v-primary": true }
                }
              ],
              attrs: { title: "Primary variant", variant: "outline-primary" }
            },
            [_vm._v(" Primary ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(186, 191, 199, 0.15)",
                  expression: "'rgba(186, 191, 199, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.v-secondary",
                  modifiers: { hover: true, "v-secondary": true }
                }
              ],
              attrs: {
                title: "Secondary variant",
                variant: "outline-secondary"
              }
            },
            [_vm._v(" Secondary ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(40, 199, 111, 0.15)",
                  expression: "'rgba(40, 199, 111, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.v-success",
                  modifiers: { hover: true, "v-success": true }
                }
              ],
              attrs: { title: "Success variant", variant: "outline-success" }
            },
            [_vm._v(" Success ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(234, 84, 85, 0.15)",
                  expression: "'rgba(234, 84, 85, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.v-danger",
                  modifiers: { hover: true, "v-danger": true }
                }
              ],
              attrs: { title: "Danger variant", variant: "outline-danger" }
            },
            [_vm._v(" Danger ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 159, 67, 0.15)",
                  expression: "'rgba(255, 159, 67, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.v-warning",
                  modifiers: { hover: true, "v-warning": true }
                }
              ],
              attrs: { title: "Warning variant", variant: "outline-warning" }
            },
            [_vm._v(" Warning ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(0, 207, 232, 0.15)",
                  expression: "'rgba(0, 207, 232, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.v-info",
                  modifiers: { hover: true, "v-info": true }
                }
              ],
              attrs: { title: "Info variant", variant: "outline-info" }
            },
            [_vm._v(" Info ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(30, 30, 30, 0.15)",
                  expression: "'rgba(30, 30, 30, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.v-dark",
                  modifiers: { hover: true, "v-dark": true }
                }
              ],
              attrs: { title: "Dark variant", variant: "outline-dark" }
            },
            [_vm._v(" Dark ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }