var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Tooltip Positions" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codePosition) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [_vm._v("Twelve options are available for positioning: ")]),
        _c("code", [
          _vm._v(
            "top, topleft, topright, right, righttop, rightbottom, bottom, bottomleft, bottomright, left, lefttop,"
          )
        ]),
        _c("span", [_vm._v(" and ")]),
        _c("code", [_vm._v("leftbottom")]),
        _c("span", [_vm._v(" aligned. The default position is top.")])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.top",
                  value: "Tooltip on top!",
                  expression: "'Tooltip on top!'",
                  modifiers: { hover: true, top: true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [_vm._v(" Tooltip on top ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.right",
                  value: "Tooltip on right!",
                  expression: "'Tooltip on right!'",
                  modifiers: { hover: true, right: true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [_vm._v(" Tooltip on right ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.left",
                  value: "Tooltip on left!",
                  expression: "'Tooltip on left!'",
                  modifiers: { hover: true, left: true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [_vm._v(" Tooltip on left ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.bottom",
                  value: "Tooltip on bottom!",
                  expression: "'Tooltip on bottom!'",
                  modifiers: { hover: true, bottom: true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [_vm._v(" Tooltip on bottom ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }