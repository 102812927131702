var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Tooltip Events" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeEvent) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: {
                id: "tooltip-button-show-event",
                variant: "outline-primary"
              }
            },
            [_vm._v(" Show Event Tooltip ")]
          ),
          _c(
            "b-tooltip",
            {
              ref: "tooltip-show",
              attrs: { target: "tooltip-button-show-event", triggers: "click" },
              on: { show: _vm.onShow }
            },
            [_vm._v(" tooltip! ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: {
                id: "tooltip-button-shown-event",
                variant: "outline-primary"
              }
            },
            [_vm._v(" Shown Event Tooltip ")]
          ),
          _c(
            "b-tooltip",
            {
              ref: "tooltip-shown",
              attrs: {
                target: "tooltip-button-shown-event",
                triggers: "click"
              },
              on: { shown: _vm.onShown }
            },
            [_vm._v(" tooltip! ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: {
                id: "tooltip-button-hide-event",
                variant: "outline-primary"
              }
            },
            [_vm._v(" Hide Event Tooltip ")]
          ),
          _c(
            "b-tooltip",
            {
              ref: "tooltip-hide",
              attrs: { target: "tooltip-button-hide-event", triggers: "click" },
              on: { hide: _vm.onHide }
            },
            [_vm._v(" tooltip! ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              staticClass: "mb-1 mb-xl-0",
              attrs: {
                id: "tooltip-button-hidden-event",
                variant: "outline-primary"
              }
            },
            [_vm._v(" Hidden Event Tooltip ")]
          ),
          _c(
            "b-tooltip",
            {
              ref: "tooltip-hidden",
              attrs: {
                target: "tooltip-button-hidden-event",
                triggers: "click"
              },
              on: { hidden: _vm.onHidden }
            },
            [_vm._v(" tooltip! ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }